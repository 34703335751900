body {
  margin: 0;
  font-family: "Century Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  background-color: #DCDCDD;
}

.bg-purple {
  background-color: #A588BF;
}

.purple {
  color: #56388F;
}

.btn-purple {
  color: #fff;
  background-color: #56388F;
}

.btn-outline-purple {
  color: #56388F;
  border-color: #56388F;
}

.btn-outline-purple:hover {
  font-weight: bold;
  background-color: #56388F;
  border-color: #56388F;
}
